import React from "react"
import { Link } from "gatsby"

import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"

import Layout from "../../../components/es/layout"
//import Image from "../components/image"
import SEO from "../../../components/es/seo"

import MainImg from "./../../../images/main_bg.jpg"

import shape1 from "./../../../images/dec/1.png"
import shape2 from "./../../../images/dec/2.png"
import shape3 from "./../../../images/dec/3.png"
import shape4 from "./../../../images/dec/4.png"
import shape5 from "./../../../images/dec/5.png"
import shape6 from "./../../../images/dec/6.png"
import shape7 from "./../../../images/dec/7.png"
import shape8 from "./../../../images/dec/8.png"

import product4 from "./../../../images/es/moonamar-balsamo-para-piel-con-argan-y-comino-negro.png"

const CorokotovoUlje = () => (
  <Layout>
    <SEO
      title="IMMMUNO NATURA – BÁLSAMO PARA PIEL CON ARGÁN Y COMINO NEGRO"
      description="Bálsamo IMMMUNO NATURA con comino negro, argán jojoba, aguacate y manteca de karité ideal para pieles secas y sensibles."
    />

    <section
      id="_heroPage"
      className="hero is-large"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body" />
    </section>

    <span className="_line" />

    <section id="_oProizvodu" className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="_slide">
              <img
                className="_img"
                src={product4}
                alt="BÁLSAMO PARA PIEL CON ARGÁN Y COMINO NEGRO"
                title="BÁLSAMO PARA PIEL CON ARGÁN Y COMINO NEGRO"
              />
              <div className="_info">
                <div className="_inner">
                  <span>IMMMUNO NATURA</span>
                  <div className="title">
                    Bálsamo para todo tipo de pieles con comino negro, argán,
                    jojoba, aguacate y manteca de karité
                  </div>
                  <div className="subtitle">Contenido: 50 ml</div>
                  <div className="price">8,90 €</div>
                </div>
                <div className="_buttonShop">
                  <Link to="/es/formulario-para-pedidos">PEDIDOS</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="_pageContent" className="section">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <Tabs>
              <TabList>
                <Tab>Descripción del producto</Tab>
                <Tab>Ingredientes y modo de empleo</Tab>
              </TabList>

              <TabPanel>
                <div className="content">
                  <p>
                    El bálsamo para corporal de IMMMUNO NATURA está pensado
                    especialmente para la hidratación y cuidado de pieles muy
                    secas y sensibles. Hidrata a fondo la piel y la protege de
                    la sequedad fortaleciendo la regeneración natural de ésta.
                  </p>
                  <p>
                    Conserva la hidratación natural de la pie, lo que hace que
                    los enrojecimientos y picores de las pieles secas
                    desaparezcan.
                  </p>
                  <p>
                    <strong>- También para bebés</strong>
                    <br />
                    <strong>- Sin perfúmenes añadidos</strong>
                  </p>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="content">
                  <h3>Ingredientes:</h3>
                  <p>
                    Nigella Sativa (Aceite de comino negro), Persea gratissima
                    (Aceite de aguacate), Cera Alba (Cera de abejas), Argania
                    Spinosa (aceite de argán), Buxus Chinensis (aceite de
                    jojoba), Butyrospermum parkii (Manteca de karité).
                  </p>
                  <h3>Aplicación:</h3>
                  <p>
                    El bálsamo se puede aplicar en todo el cuerpo incluso en
                    labios muy agrietados. Recomendaciones: aplicar una capa muy
                    fina en el lugar donde se requiera. Si se tienen las manos
                    muy secas y cortadas, aplicar en las manos antes de irse a
                    dormir y ponerse unos guantes de algodón; dejar que actúe
                    durante toda la noche.
                  </p>
                  <p>
                    <strong>
                      El bálsamo es adecuado tanto para adultos como para niños.
                    </strong>
                  </p>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>
  </Layout>
)

export default CorokotovoUlje
